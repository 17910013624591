// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activate-activate-account-js": () => import("./../../../src/pages/activate/ActivateAccount.js" /* webpackChunkName: "component---src-pages-activate-activate-account-js" */),
  "component---src-pages-activate-js": () => import("./../../../src/pages/activate/[...].js" /* webpackChunkName: "component---src-pages-activate-js" */),
  "component---src-pages-carousel-js": () => import("./../../../src/pages/carousel.js" /* webpackChunkName: "component---src-pages-carousel-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gewinnspiel-teilnahmebedingungen-index-js": () => import("./../../../src/pages/gewinnspiel-teilnahmebedingungen/index.js" /* webpackChunkName: "component---src-pages-gewinnspiel-teilnahmebedingungen-index-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-modules-[id]-js": () => import("./../../../src/pages/modules/[id].js" /* webpackChunkName: "component---src-pages-modules-[id]-js" */),
  "component---src-pages-modules-js": () => import("./../../../src/pages/modules.js" /* webpackChunkName: "component---src-pages-modules-js" */),
  "component---src-pages-modules-list-js": () => import("./../../../src/pages/modulesList.js" /* webpackChunkName: "component---src-pages-modules-list-js" */),
  "component---src-pages-new-page-js": () => import("./../../../src/pages/new-page.js" /* webpackChunkName: "component---src-pages-new-page-js" */),
  "component---src-pages-new-volume-range-js": () => import("./../../../src/pages/newVolumeRange.js" /* webpackChunkName: "component---src-pages-new-volume-range-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-product-item-js": () => import("./../../../src/pages/product-item.js" /* webpackChunkName: "component---src-pages-product-item-js" */),
  "component---src-pages-resetpassword-[email]-[key]-index-js": () => import("./../../../src/pages/resetpassword/[email]/[key]/index.js" /* webpackChunkName: "component---src-pages-resetpassword-[email]-[key]-index-js" */),
  "component---src-pages-resetpassword-index-js": () => import("./../../../src/pages/resetpassword/index.js" /* webpackChunkName: "component---src-pages-resetpassword-index-js" */),
  "component---src-pages-resetpassword-reset-password-page-js": () => import("./../../../src/pages/resetpassword/ResetPasswordPage.js" /* webpackChunkName: "component---src-pages-resetpassword-reset-password-page-js" */),
  "component---src-pages-resetpassword-send-password-reset-page-js": () => import("./../../../src/pages/resetpassword/SendPasswordResetPage.js" /* webpackChunkName: "component---src-pages-resetpassword-send-password-reset-page-js" */)
}

