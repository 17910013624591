const DISPLAY_MODAL_VIDEO = "MODAL/DISPLAY"
const DISPLAY_MENU = "MENU/DISPLAY"
const STORE_FORM_DATA = "CHECKOUT/STORE_FORM_DATA"
const CHANGE_CHECKOUT_STEP = "CHECKOUT/CHANGE_CHECKOUT_STEP"

export const videoModalAction = payload => {
  return { type: DISPLAY_MODAL_VIDEO, payload }
}

export const displayMenuAction = () => {
  return { type: DISPLAY_MENU }
}

export const storeFormDataAction = payload => {
  return { type: STORE_FORM_DATA, payload }
}

export const changeCheckoutStep = payload => {
  return { type: CHANGE_CHECKOUT_STEP, payload }
}

export {DISPLAY_MODAL_VIDEO,DISPLAY_MENU,STORE_FORM_DATA,CHANGE_CHECKOUT_STEP}