import { call, put, takeEvery } from 'redux-saga/effects'
import {CHANGE_CHECKOUT_STEP, changeCheckoutStep, STORE_FORM_DATA} from "./actions";
import {useSelector} from "react-redux";

function* syncLocalStorage(action) {
    try {
        yield call((args) => {
            const state = useSelector(state => state)
            console.log("state",state)
        }, action);
    } catch (e) {
        // yield put({type: "USER_FETCH_FAILED", message: e.message});
    }
}

function* mySaga() {
    yield takeEvery(STORE_FORM_DATA, syncLocalStorage);
}

export default mySaga;