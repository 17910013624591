import { createStore as reduxCreateStore,applyMiddleware } from "redux"
import createSagaMiddleware from 'redux-saga'
import {composeWithDevTools} from "redux-devtools-extension";

import mySaga from './sagas'
import reducer from "./reducer"
import defaultState from "./defaultState"

const initialState = { menu: false, modal: { display: false, vimeo: 0 } }
// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const isBrowser = typeof window !== "undefined"

// mount it on the Store
const createStore = () => {

    let getDataFromLocalStorage = {}
    let combinedState = defaultState

        if(isBrowser) {
            getDataFromLocalStorage = localStorage.getItem("__checkout") ? JSON.parse(localStorage.getItem("__checkout")) : {}
        }
        
        if(typeof getDataFromLocalStorage.formData !== "undefined"){
            combinedState = {...combinedState, checkout : {...getDataFromLocalStorage}}
        }
    
    const store = reduxCreateStore(reducer, combinedState, composeWithDevTools(
        applyMiddleware(sagaMiddleware),
        // other store enhancers if any
    ))

    // then run the saga
    sagaMiddleware.run(mySaga)
    return store
}

export default createStore
