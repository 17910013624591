const defaultState = {
    menu: false, modal: {display: false, vimeo: 0},
    checkout: {
        currentStep: 1,
        formData: {
            paymentType: "paypal",
            email: "",
            password: "",
            firstName: "",
            secondName: "",
            address: "",
            city: "",
            postcode: "",
            phone: "",
        }
    },
    authorization: {
        access: false,
        token: ""
    }
}

export default defaultState;