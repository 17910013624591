import {CHANGE_CHECKOUT_STEP, DISPLAY_MENU, DISPLAY_MODAL_VIDEO, STORE_FORM_DATA} from "./actions";

const reducer = (state, action) => {
    switch (action.type) {
        case DISPLAY_MENU:
            return Object.assign({}, state, {
                menu: !state.menu,
            })
        case DISPLAY_MODAL_VIDEO:
            return Object.assign({}, state, { modal: { ...action.payload } })
        case STORE_FORM_DATA:
            state.checkout.formData = {...state.checkout.formData, ...action.payload}
            localStorage.setItem('__checkout', JSON.stringify(state.checkout));
            return state
        case CHANGE_CHECKOUT_STEP:
            state.checkout.currentStep = action.payload.currentStep
            return state
        default:
            return state
    }
}

export default reducer;